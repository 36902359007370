export default () => {
  const select = document.querySelector('#q_target_material_uses_target_material_id_in')
  
  if(select) {
    const bluesq = "🟦 "
    const yellowsq = "🟨 "
    const redsq = "🟥 "
    const greensq = "🟩 "
    const orangesq = "🟧 "
    const greysq = "⬜ " 

    for (let i = 0; i < select.length; i++) {
     let option = select.options[i];
      if (option.value >= 1 && option.value <= 3) {
        option.insertAdjacentHTML("afterbegin", bluesq);
      } else if (option.value >= 3 && option.value <= 5) {
        option.insertAdjacentHTML("afterbegin", yellowsq);
      } else if (option.value >= 5 && option.value <= 7) {
        option.insertAdjacentHTML("afterbegin", redsq);
      } else if (option.value >= 7 && option.value <= 13) {
        option.insertAdjacentHTML("afterbegin", greensq);
      } else if (option.value >= 13 && option.value <= 16) {
        option.insertAdjacentHTML("afterbegin", orangesq);
      } else if (option.value >= 16 && option.value <= 19) {
        option.insertAdjacentHTML("afterbegin", greysq);
      }
    }    
  }

}
