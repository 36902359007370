import autocomplete from 'js-autocomplete';

export default () => {
  const searchInput = document.getElementById('item-order-code');
  const titleInput = document.getElementById('item-title');
  // const form = document.querySelector('.purchases-form');
  const submit = document.getElementById('update-btn');
  const preventReload = document.getElementById('prevent-reload');

  if (searchInput) {
    new autocomplete({
      selector: searchInput,
      minChars: 1,
      source: async (term, suggest) => {
        try {
          const response = await fetch(
            `/autocomplete/order_codes?searchterm=${term}`
          );
          const data = await response.json();
          suggest(data);
        } catch (error) {
          return error;
        }
      },
      cache: false,
      delay: 0,
      onSelect: function (e, term, item) {
        if (submit != null) {
          submit.click();
        }
        
        if (preventReload != null) {
          return;
        }

        // window.location.reload();
      },
    });
  }

  if (titleInput) {
    new autocomplete({
      selector: titleInput,
      minChars: 1,
      source: async (term, suggest) => {
        try {
          const response = await fetch(
            `/autocomplete/item_titles?searchterm=${term}`
          );
          const data = await response.json();
          suggest(data);
        } catch (error) {
          return error;
        }
      },
      cache: false,
      delay: 0,
      onSelect: function (e, term, item) {
        if (submit != null) {
          submit.click();
        }
        
        if (preventReload != null) {
          return;
        }

        // window.location.reload();
      },
    });
  }
};
