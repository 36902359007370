import FontFaceObserver from 'fontfaceobserver';

const OpenSans = new FontFaceObserver('Open Sans');
OpenSans.load()
  .then(() => {
    document.body.classList.add('OpenSans-loaded');
  })
  .catch((err) => {
    document.body.classList.add('OpenSans-failed');
  });
