import Maps from '@djforth/gmaps_helper';
var map = Maps('AIzaSyDEbeg_yrvY7vihGS1AhATOrSb3T3D2vCw');

if (map) {
  map.setBounds();
  map.setZoom(16);
  map.addConfig('google-map', {
    styles: [],
  });

  map.load();
}
