import './load_service_worker';
import './font_observer';

import lozad from 'lozad';
import Carousel from '@morsedigital/vanilla-carousel';
import carouselSVG from '@morsedigital/vanilla-carousel/assets/carousel.svg';
import autocompleteSearch from './autocomplete_search';
import hamburger from './hamburger';
import deliveryOption from './delivery_option';
import targetMaterialsDropdown from './target_materials_dropdown';
import searchCheckboxes from './search_checkboxes';
import categoryTabs from './category_tabs';
import nonSignedInMessage from './non_signed_in_message';
import createToolingListMessage from './create_tooling_list_message';
import './map';
import orderAutoFocus from './order_form_autofocus';
import downloadRefresh from './download_refresh';

window.autocompleteSearch = autocompleteSearch;

(() => {
  Carousel();
  autocompleteSearch();
  hamburger();
  deliveryOption();
  targetMaterialsDropdown();
  searchCheckboxes();
  orderAutoFocus();
  createToolingListMessage();
  nonSignedInMessage();
  categoryTabs();
  
  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();
})();

document.addEventListener('turbo:load', () => {
  (() => {
    Carousel();
    autocompleteSearch();
    hamburger();
    deliveryOption();
    targetMaterialsDropdown();
    searchCheckboxes();
    orderAutoFocus();
    createToolingListMessage();
    nonSignedInMessage();
    categoryTabs();    
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
  })();
});