export default () => {
  const nav = document.getElementById('navigation');
  const hamburger = [...document.querySelectorAll('.hamburger')];
  
  if (!nav || hamburger.length === 0) return;

  hamburger.forEach((icon) => {
    icon.addEventListener('click', () => {
      nav.classList.toggle('open-nav');
      icon.classList.toggle('toggle-hamburger');
    });
  });
};
