export default () => {

  const purchaseTarget = document.querySelector('.purchases'); 
  const toolingListTarget = document.querySelector('.tooling_lists'); 

  let orderObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
        let ioc = document.getElementById('item-order-code');
        ioc.focus();
        ioc.select();
    });
  });

  let orderConfig = { attributes: true, childList: true, characterData: true}
  
  if(purchaseTarget){
    orderObserver.observe(purchaseTarget, orderConfig);
  }

  if(toolingListTarget){
    orderObserver.observe(toolingListTarget, orderConfig);
  }
};