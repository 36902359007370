export default () => {
    const buttons = [...document.querySelectorAll('.sign-in-modal-btn')];
    const body = document.body;
    const modal = document.getElementById('sign-in-modal');
    const close = document.querySelector('.sign-in-modal-content__close')

    if(modal){    
        if(buttons) {
            buttons.forEach((btn) => {
                const span = document.createElement('span');
                const newButton = span;

                newButton.innerHTML = btn.innerHTML;
                newButton.className = btn.className;
                btn.parentElement.replaceChild(newButton, btn);

                newButton.addEventListener('click', () => {
                    body.style.position = 'relative';
                    modal.classList.add('sign-in-modal--show');
                });
            });
        }

        if(close) {
            close.addEventListener('click', () => {
                modal.classList.remove('sign-in-modal--show');
            });
        } else {
            modal.classList.toggle('sign-in-modal--show');
        }
    }   
};