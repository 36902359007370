import './modules';
import "trix";
import "@hotwired/turbo-rails"
import "@rails/actiontext";

// Contact honeypot
const honeyPot = document.getElementById('contact-honeypot');
if (honeyPot) {
  honeyPot.style.display = 'none';
}
import "./controllers"

console.log('application.js loaded')
