import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cookie"
export default class extends Controller {
  static targets = ["closebtn", "container"]

  connect(){
    
    const cookieStorage = {
      getItem: (item) => {
        const cookies = document.cookie
            .split(';')
            .map(cookie => cookie.split('='))
            .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
        return cookies[item];
      },
      setItem: (item, value) => {
        document.cookie = `${item}=${value};`
      }
    }
  
    const storageType = cookieStorage;
    const consentPropertyName = 'permission';
    const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
    const saveToStorage = () => storageType.setItem(consentPropertyName, true);

    const acceptFn = event => {
      event.preventDefault;
      saveToStorage(storageType);
      consentPopup.classList.add('hidden');
    }

    const consentPopup = this.containerTarget;
    const acceptBtn = this.closebtnTarget;
    acceptBtn.addEventListener('click', acceptFn);

    if (shouldShowPopup(storageType)) {
      consentPopup.classList.remove('hidden');
    }


  }
}
