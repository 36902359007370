export default () => {
  const tabs = [...document.querySelectorAll('.catalogues-categories__tab')];
  const categories = [...document.querySelectorAll('.catalogues-category')];
  let activeTab = document.querySelector('active-tab');
  const activeCat = document.querySelectorAll('.active-category');
  let orderCode = document.getElementById('item-order-code');

  console.log(tabs)

  if(orderCode) {
    orderCode.focus();
    orderCode.select();
  }

  if(!activeCat) return;

  if(activeCat.length > 0){
    categories[0].classList.add('active-category')
    tabs[0].classList.add('active-tab');
  }  

  tabs.forEach((tab) => {
    tab.addEventListener('click', (e) => {
      e.preventDefault();

      const openTab = [...document.querySelectorAll('.active-tab')][0];

      if (openTab) {
        openTab.classList.remove('active-tab');
      }

      const openCategory = [
        ...document.querySelectorAll('.active-category'),
      ][0];

      if (openCategory) {
        openCategory.classList.remove('active-category');
      }

      const category = document.querySelector(`[data-open="${e.target.id}"]`);

      category.classList.add('active-category');
      tab.classList.add('active-tab');
    });
  });

};
