export default () => {
    // const select = document.querySelector('.change-delivery-input__checkbox');
    const selects = [...document.querySelectorAll('.change-delivery-input__checkbox')];
    const message = document.querySelector('.change-delivery-input__copy');

    if(selects) {
        selects.forEach((select) => {
            select.addEventListener('change', () => {
                if(select.value === '1') {
                    message.classList.remove('change-delivery-input__copy-show')
                } else {
                    message.classList.add('change-delivery-input__copy-show')
                }
            });
        });
    }
};
