export default () => {
  const buttons = [...document.querySelectorAll('.tooling-list-modal-btn')];
  const body = document.body;
  const modal = document.getElementById('tooling-list-modal');
  const close = document.querySelector('.tooling-list-modal-content__close')

  if(modal){
      if(buttons) {
          buttons.forEach((btn) => {
              const span = document.createElement('span');
              const newButton = span;

              newButton.innerHTML = btn.innerHTML;
              newButton.className = btn.className;
              btn.parentElement.replaceChild(newButton, btn);

              newButton.addEventListener('click', () => {
                  body.style.position = 'relative';
                  modal.classList.add('tooling-list-modal--show');
              });
          });
      }

      if(close) {
          close.addEventListener('click', () => {
              modal.classList.remove('tooling-list-modal--show');
          });
      } else {
          modal.classList.toggle('tooling-list-modal--show');
      }
  }
};
